import { unitDriversApi } from '@/api'
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      envetsSelected: [],
      isAllCheckedEvent: false,
      stateIconEvent: '',
      isIndeterminateEvent: false
    }
  },

  methods: {
    changeCheckedEvent(unit) {
      this.envetsSelected = unit.event.isInArray
        ? [...this.envetsSelected, unit.event]
        : this.envetsSelected.filter(el => el.id !== unit.event.id)
      const selectedLength = this.envetsSelected.length
      if (selectedLength === this.allunitsDrivers.length) {
        this.isAllCheckedEvent = true
        this.isIndeterminateEvent = true
        this.stateIconEvent = 'select'
      } else if (!selectedLength) {
        this.isAllCheckedEvent = false
        this.isIndeterminateEvent = false
        this.stateIconEvent = 'normal'
      } else {
        this.isAllCheckedEvent = false
        this.isIndeterminateEvent = true
        this.stateIconEvent = 'select'
      }
    },
    checkAllEvents($event) {
      if ($event.target.checked) {
        console.log('ok0', $event)
        this.envetsSelected = this.events
        this.envetsSelected.forEach(el => (el.isInArray = true))
        this.isAllCheckedEvent = true
        this.isIndeterminateEvent = false
        this.stateIconEvent = 'select'
      } else {
        this.envetsSelected.forEach(el => (el.isInArray = false))
        this.envetsSelected = []
        this.isAllCheckedEvent = false
        this.isIndeterminateEvent = false
        this.stateIconEvent = 'normal'
      }
    },

    handlDeleteEvents() {
      let idsArr = []
      this.envetsSelected.forEach(shift => {
        idsArr.push(shift.id)
      })
      this.$store.dispatch('events/DELETE', idsArr)
      this.stateIconEvent = 'normal'
      this.isAllCheckedEvent = false
      this.isIndeterminateEvent = false

      this.envetsSelected = []
    },

    handleTimeChange(val) {
      let newUsers = []
      let data = {
        from: 0,
        count: 100,
        unit: this.unit.id,
        date_from: val[0],
        date_to: val[1]
      }
      unitDriversApi.getUnitDriversFilter(
        data,
        response => {
          response.data.list.forEach(el =>
            this.users.forEach(item => {
              if (item.id === el.user_id) {
                newUsers.push({ ...el, name: item.name })
              }
            })
          )

          this.allunitsDrivers = newUsers
          this.unitDrivers = response.data.list.map(driver => {
            const user = this.userById(driver.user.id)
            return {
              ...driver,
              user: user
            }
          })
        },
        error => {
          this.$showError(error.response.data.message)
        }
      )
    },

    getUnitDrivers() {
      let unitdrivers = []
      unitDriversApi.getUnitDrivers(
        this.unit.id,
        response => {
          response.data.list.forEach(el => {
            this.users.forEach(item => {
              if (item.id === el.user_id) {
                unitdrivers.push({ ...el, name: item.name })
              }
            })
            if (el['user_id'] === undefined) {
              unitdrivers.push(el)
            }
          })
          this.allunitsDrivers = unitdrivers
          this.unitDrivers = response.data.list.map(driver => {
            const user = this.userById(driver.user.id)
            return {
              ...driver,
              user: user
            }
          })
        },
        error => {
          this.$showError(error.response.data.message)
        }
      )
    }
  },
  computed: {
    ...mapState('dictionary', {
      event_type: state => state.event_type
    }),
    ...mapGetters('users', ['userById']),
    ...mapState('users', {
      users: state => {
        return state.users.filter(user => user.is_driver)
      }
    }),
    ...mapState('events', {
      events: state => state.events
    }),

    getEvents() {
      console.log('get Events22')
      //   return this.unitDrivers.filter(
      //     user =>
      //       JSON.stringify(user)
      //         .toLowerCase()
      //         .indexOf(this.filterStringDrivers.toLowerCase()) !== -1
      //   )
    }
  },

  watch: {
    activeTab: function(val) {
      console.log(this.unit.id)
      let formData = {
        from: 0,
        count: 100,
        sortDesc: false,
        conditions: [
          {
            field: 'unit.id',
            value: this.unit.id
          },
          { field: 'date_from', value: this.eventsDateRange[0] },
          { field: 'date_to', value: this.eventsDateRange[1] }
        ]
      }

      if (val === 'События') {
        this.$store.dispatch('events/GET_LIST', formData)
      }
    }
  }
}

import { mapState } from 'vuex'
import { objectsApi } from '@/api'
import { v4 as uuidv4 } from 'uuid'
export default {
  data() {
    return {
      isNewSensor: false,
      isUpdating: false,
      programaticSensorReset: false,
      newSensor: {},
      isSensorNameMissing: false,
      isSensorTypeMissing: false,
      isSensorParamMissing: false,
      isSensorFormulaMissing: false
    }
  },
  watch: {
    'newSensor.name': function(val) {
      this.isSensorNameMissing = !this.programaticSensorReset && val === ''
    },
    'newSensor.type.key': function(val) {
      this.isSensorTypeMissing = !this.programaticSensorReset && val === ''
    },
    'newSensor.formula': function(val) {
      this.isSensorFormulaMissing = !this.programaticSensorReset && val === ''
    },
    'newSensor.param1.param_key': function(val) {
      this.isSensorParamMissing =
        !this.programaticSensorReset &&
        val === '' &&
        !this.newSensor.param2.param_key
    },
    'newSensor.param2.param_key': function(val) {
      this.isSensorParamMissing =
        !this.programaticSensorReset &&
        val === '' &&
        !this.newSensor.param1.param_key
    }
  },
  methods: {
    updateParam(param, paramNum) {
      console.log('here', paramNum, param)
      if (paramNum === 1) {
        this.newSensor.param1 = JSON.parse(JSON.stringify(param))
      } else {
        this.newSensor.param2 = JSON.parse(JSON.stringify(param))
      }
    },
    terminalTypeChange(val) {
      console.log('terminal changed to:', val)
      // this.unit.sensors = this.unit.sensors.filter(sensor => {
      //   return this.params[val]
      //     .map(item => item.value)
      //     .includes(sensor.param1.param_key && sensor.param2.param_key)
      // })
    },
    editSensor(sensor, isUpdate) {
      this.isUpdating = !this.isUpdating
      this.programaticSensorReset = true
      this.newSensor = JSON.parse(JSON.stringify(sensor))
      setTimeout(() => (this.programaticSensorReset = false), 100)
      this.isNewSensor = true
    },
    validSensor() {
      let error = true
      if (!this.newSensor.name) {
        this.isSensorNameMissing = true
        error = false
      }
      if (!this.newSensor.type.key) {
        this.isSensorTypeMissing = true
        error = false
      }
      if (
        !this.newSensor.param1.param_key &&
        !this.newSensor.param2.param_key
      ) {
        this.isSensorParamMissing = true
        error = false
      }
      if (!this.newSensor.formula) {
        this.isSensorFormulaMissing = true
        error = false
      }
      return error
    },
    createOrUpdateSensor() {
      if (this.validSensor()) {
        if (this.newSensor.id) {
          const sensorIndex = this.unit.sensors.findIndex(
            sensor => sensor.id === this.newSensor.id
          )
          let sensors = [
            ...this.unit.sensors.slice(0, sensorIndex),
            JSON.parse(JSON.stringify(this.newSensor)),
            ...this.unit.sensors.slice(sensorIndex + 1)
          ]

          let validationForm = {
            sensors: sensors,
            terminal_type: this.unit.terminal_type.key
          }
          objectsApi.validateSensor(
            validationForm,
            response => {
              if (response.status === 200) {
                this.unit.sensors = sensors
                this.isNewSensor = false
                this.isUpdating = false
              }
            },
            error => {
              console.log('error edit', error)
              this.$showError(error.response.data.message)
            }
          )
        } else {
          let newsensor = {
            ...this.newSensor,
            id: null
          }
          let sensors = [...this.unit.sensors, newsensor]
          let validationForm = {
            sensors: sensors,
            terminal_type: this.unit.terminal_type.key
          }
          objectsApi.validateSensor(
            validationForm,
            response => {
              if (response.status === 200) {
                console.log('verfied sucess')
                this.unit.sensors.push(newsensor)
                this.isNewSensor = false
                this.isUpdating = false
              }
            },
            error => {
              this.$showError(error.response.data.message)
              console.log('error', error.response)
            }
          )
          // console.log('sensor saved to unit', this.unit)
        }

        // this.isNewSensor = false
        // this.isUpdating = false
      }
    },
    deleteSensor(id) {
      const sensorIndex = this.unit.sensors.findIndex(
        sensor => sensor.id === id
      )
      this.unit.sensors.splice(sensorIndex, 1)
    },
    createSensor(sensor, isUpdate) {
      this.isNewSensor = !this.isNewSensor
      this.isUpdating = !this.isUpdating
      this.programaticSensorReset = true
      this.newSensor = {
        name: '',
        type: {
          key: '',
          value: ''
        },
        param1: {
          param_key: '',
          smoothing: 0,
          min_valid: undefined,
          max_valid: undefined,
          calibrationABlist: [],
          calibrationXYlist: []
        },
        param2: {
          param_key: '',
          smoothing: 0,
          min_valid: undefined,
          max_valid: undefined,
          calibrationABlist: [],
          calibrationXYlist: []
        },
        formula: '',
        id: '',
        hide_in_reports: false,
        hide_in_monitorings: false
      }
      setTimeout(() => (this.programaticSensorReset = false), 100)
    }
  },
  computed: {
    ...mapState('dictionary', {
      sensor_type: state => state.sensor_type,
      params: state => state.params,
      formula: state => state.formula
    }),
    terminalParams: function() {
      return this.params[this.unit.terminal_type.key]
    },
    filterDataSensors() {
      // console.log(this.unit.sensors)
      return this.unit.sensors.filter(
        sensor =>
          JSON.stringify(sensor)
            .toLowerCase()
            .indexOf(this.filterStringSensors.toLowerCase()) !== -1
      )
    }
  }
}

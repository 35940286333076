import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      extraTab: 'cf_motohours',
      isNewExtra: false,
      motohoursLabel: false,
      tripsLabel: false,
      fuelLabel: false,
      etcLabel: false,
      customLabel: false,
      newExtra: {},
      isExtraNameMissing: false,
      isExtraValueMissing: false,
      programaticExtraReset: false,
    }
  },
  watch: {
    'newExtra.key': function (val) {
      this.isExtraNameMissing =
        (!this.programaticExtraReset && val === '') ||
        this.unit.custom_fields.map((k) => k.key).includes(val)
    },
    'newExtra.value': function (val) {
      this.isExtraValueMissing = !this.programaticExtraReset && val === ''
    },
    extraTab() {
      this.isNewExtra = false
    },
  },
  methods: {
    validExtra() {
      let error = true
      if (
        this.newExtra.key === '' ||
        this.unit.custom_fields.map((k) => k.key).includes(this.newExtra.key)
      ) {
        this.isExtraNameMissing = true
        error = false
      }
      if (this.newExtra.value === '') {
        this.isExtraValueMissing = true
        error = false
      }

      return error
    },
    addExtraToUnit(extraTab) {
      console.log('zinga', this.newExtra)
      if (this.validExtra()) {
        this.newExtra.name = this.newExtra.key
        this.newExtra.show_in_monitoring = true
        this.newExtra.group = extraTab
        this.unit.custom_fields.push(JSON.parse(JSON.stringify(this.newExtra)))
        this.isNewExtra = false
      }
    },
    deleteExtra(key) {
      this.unit.custom_fields = this.unit.custom_fields.filter(
        (cf) => cf.key !== key
      )
    },
    createExtra(extraTab) {
      this.isNewExtra = !this.isNewExtra
      this.programaticExtraReset = true
      this.newExtra = {
        name: '',
        value: '',
        key: '',
        group: extraTab,
      }
      setTimeout(() => (this.programaticExtraReset = false), 100)
    },
  },
  computed: {
    ...mapGetters('properties', ['customField']),
    filterDataExtra() {
      return this.extraTab === 'cf_motohours'
        ? this.filterDataExtraMotohours
        : this.extraTab === 'cf_trips'
        ? this.filterDataExtraTrips
        : this.extraTab === 'cf_fuel'
        ? this.filterDataExtraFuel
        : this.extraTab === 'cf_etc'
        ? this.filterDataExtraEtc
        : this.filterDataExtraCustom
    },
    filterDataExtraMotohours() {
      return this.unit.custom_fields.filter(
        (cf) =>
          cf.group === 'cf_motohours' &&
          JSON.stringify(cf)
            .toLowerCase()
            .includes(this.filterStringExtra.toLowerCase())
      )
    },
    filterDataExtraTrips() {
      let trips = this.unit.custom_fields.filter(
        (cf) =>
          cf.group === 'cf_trips' &&
          JSON.stringify(cf)
            .toLowerCase()
            .includes(this.filterStringExtra.toLowerCase())
      )
      return trips
    },
    filterDataExtraFuel() {
      return this.unit.custom_fields.filter(
        (cf) =>
          cf.group === 'cf_fuel' &&
          JSON.stringify(cf)
            .toLowerCase()
            .includes(this.filterStringExtra.toLowerCase())
      )
    },
    filterDataExtraEtc() {
      return this.unit.custom_fields.filter(
        (cf) =>
          cf.group === 'cf_etc' &&
          JSON.stringify(cf)
            .toLowerCase()
            .includes(this.filterStringExtra.toLowerCase())
      )
    },
    filterDataExtraCustom() {
      return this.unit.custom_fields.filter(
        (cf) =>
          cf.group === 'cf_custom' &&
          JSON.stringify(cf)
            .toLowerCase()
            .includes(this.filterStringExtra.toLowerCase())
      )
    },
  },
}

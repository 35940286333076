<template lang="pug">
.absolute.bottom-5.top-5.left-5.z-30.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module(
  :style='activeTab === "Дополнительные" ? { "grid-template-rows": "auto auto auto auto 1fr" } : { "grid-template-rows": "auto auto auto 1fr" }'
)
  .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("units.create") : $t("units.update") }}
  .flex.justify-end.col-start-2.items-center
    img.history-image.cursor-pointer(
      style='margin-right:20px'
      src='@/assets/icons/history.svg',
      @click='showHistoryChanges(unit.id)'

    )
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .col-start-1.col-end-3.flex.mt-4.justify-between
    .flex.flex-col(class='w-7/12')
      el-select(
        type='text',
        v-model='activeTab',
        filterable,
        :no-match-text='$t("noMatch")'
      )
        el-option(
          v-for='(item, index) in unitModules',
          :key='index',
          :label='item',
          :value='item'
        )
      transition(name='el-zoom-in-top')
        transition(name='el-fade-in-linear')
          .cont(v-if='activeTab !== "Основные"')
            .text-notify.text-xs.mt-4(
              v-if='isIconMissing || isNameMissing || isUnitTypeMissing || isTerminalTypeMissing || isImeiMissing || isPhonenumberMissing || isIconMissing'
            ) {{ $t("objects.fillfield") }}

    .relative(v-if='envetsSelected.length && activeTab === "События"')
      //- DELETE EVENTS
      Svg-editunitTrash(
        @delete-events='handlDeleteEvents',
        :activeTab='"events"',
        :envetsSelected='envetsSelected'
      )

    skif-button(
      v-if='!admin && !isNewDriver && !isNewDriverGroup && !isNewSensor && !isEditDriver',
      width='28',
      @click='createOrUpdate'
    ) {{ title === 1 ? $t("create") : $t("change") }}

    skif-button(
      v-if='isEditDriver || !admin && isNewDriver',
      width='28',
      @click='createOrUpdateDriver'
    ) Сохранить

    skif-button(
      v-if='!admin && isNewDriverGroup',
      width='28',
      @click='createOrUpdateDriverGroup'
    ) Сохранить

  skif-search.col-start-1.col-end-3.mt-6(
    v-if='activeTab !== "Основные" && activeTab !== "Смены" && activeTab !== "События" && activeTab !== "Датчики"'
    v-model='filterString'
    :placeholder='$t("search")'
    @searching="searching"
  )


  Main-tab(
    v-if='activeTab === "Основные"'
    :unit='unit'
    :isNameMissing='isNameMissing'
    :isUnitTypeMissing='isUnitTypeMissing'
    :isTerminalTypeMissing='isTerminalTypeMissing'
    :unitImeis='terminals'
    :unitTypes='unitTypes'
    :terminalTypes='terminalTypes'
    :isPhonenumberMissing='isPhonenumberMissing'
    :isImeiMissing='isImeiMissing'
    :title='title'
    :isIconMissing='isIconMissing'
    :ports='ports'
    @terminal-type-change='terminalTypeChange(unit.terminal_type.key)'
    :role='role'
  )
  Additional-tab(
    v-if='activeTab === "Дополнительные"',
    :role='role',
    :customFields='customFields',
    :unit='unit',
    :filterStringExtra='filterStringExtra'
  )
  //- Sensors-tab(
  //-   v-if='activeTab === "Датчики"',
  //-   :unit='unit',
  //-   :filterStringSensors='filterStringSensors',
  //-   @param1='handlparam1',
  //-   @param2='handlparam2'
  //- )
  .col-start-1.col-end-3.my-4.overflow-y-auto.overflow-x-hidden.bg-white(
    v-if='activeTab === "Датчики"'
    :style="{ boxShadow: isNewSensor ? '0px 0px 10px rgba(0, 0, 0, 0.1)' : '', margin: !isNewSensor ? '24px 12px' :'', padding:isNewSensor ? '24px 12px' : '' }"
  )
    table.w-full.table-fixed
      thead.text-left.bg-white
        tr.bg-white
          th.bg-white.top-0.sticky
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ isNewSensor ? $t("objects_tabs.sensors.sensors_create") : $t("name") }}
          th.bg-white.top-0.sticky.truncate.w-36(:style="{ width: isNewSensor ? 0 : '9rem' }")
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold(
              v-if='!isNewSensor'
            ) {{ $t("type") }}
          th.bg-white.top-0.sticky.w-32
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold(
              v-if='!isNewSensor'
            ) {{ $t("objects_tabs.sensors.option1") }}
          th.w-6.text-center.sticky.top-0.bg-white
            svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-all.ease-in-out.duration-200.transform(
              :class='isNewSensor ? "rotate-45" : "rotate-0"',
              width='16',
              height='16',
              fill='none',
              xmlns='http://www.w3.org/2000/svg',
              @click='createSensor'
            )
              path(
                fill-rule='evenodd',
                clip-rule='evenodd',
                d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z'
              )

        tr(v-if='isNewSensor || isUpdating')
          th.bg-white(:colspan='4')
            .flex.items-end.my-3
              .flex.flex-col(class='w-2/3')
                .flex.flex-col.relative
                  span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("name") }}
                  el-input.mt-3.w-56(
                    type='text',
                    v-model='newSensor.name',
                    :class='{ "input-error": isSensorNameMissing }'
                  )
                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isSensorNameMissing'
                    ) {{ $t("objects.name") }}
                .flex.flex-col.relative
                  span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.type_sensor") }}
                  el-select.mt-3.w-56(
                    v-model='newSensor.type',
                    :class='{ "input-error": isSensorTypeMissing }'
                  )
                    el-option(
                      v-for='item in sensor_type',
                      :key='item.key',
                      :label='item.value',
                      :value='item'
                    )
                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isSensorTypeMissing'
                    ) {{ $t("objects_tabs.sensors.error_type_sensor") }}
                .flex.flex-col.relative
                  span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.option1") }}
                  .flex.mt-3.items-center.justify-between
                    el-select.w-56(
                      v-model='newSensor.param1.param_key',
                      clearable,
                      filterable,
                      :class='{ "input-error": isSensorParamMissing }'
                    )
                      el-option(
                        v-for='item in terminalParams',
                        :key='item.value',
                        :label='item.label',
                        :value='item.value'
                      )
                    svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
                      width='18',
                      height='18',
                      fill='none',
                      xmlns='http://www.w3.org/2000/svg',
                      @click='$emit("param1", JSON.parse(JSON.stringify(newSensor.param1)))'
                    )
                      path(
                        d='M5.952 17.991a2.025 2.025 0 01-1.215-.413l-1.863-1.386a2.132 2.132 0 01-.44-2.896 1.853 1.853 0 00.206-1.673l-.054-.144A1.646 1.646 0 001.58 10.38h-.144A2.106 2.106 0 01.103 7.736L.84 5.397A1.96 1.96 0 012.593 3.92c.26-.018.521.016.767.101a1.737 1.737 0 001.602-.26l.117-.09a1.745 1.745 0 00.657-1.36v-.215A2.087 2.087 0 017.796 0h2.295a2.035 2.035 0 011.44.603c.396.403.617.946.612 1.511v.252a1.583 1.583 0 00.621 1.286l.1.072a1.566 1.566 0 001.43.234l.306-.099a2.035 2.035 0 012.592 1.359l.71 2.266a2.122 2.122 0 01-1.313 2.636l-.18.063a1.701 1.701 0 00-1.107 1.152 1.796 1.796 0 00.225 1.484l.234.342a2.14 2.14 0 01-.45 2.905l-1.809 1.395a2.018 2.018 0 01-2.898-.477l-.107-.153a1.575 1.575 0 00-1.35-.702 1.62 1.62 0 00-1.287.693l-.207.297a2.024 2.024 0 01-1.35.872 1.79 1.79 0 01-.351 0zM2.164 8.654a3.447 3.447 0 012.141 2.249v.108a3.597 3.597 0 01-.414 3.256.342.342 0 000 .459l1.935 1.466a.225.225 0 00.333-.063l.207-.297a3.393 3.393 0 015.58 0l.107.162a.27.27 0 00.162.108.223.223 0 00.171-.045l1.854-1.403a.324.324 0 00.063-.44l-.234-.343a3.597 3.597 0 01-.477-3.076 3.525 3.525 0 012.24-2.348l.18-.063a.306.306 0 00.172-.396l-.702-2.24a.315.315 0 00-.18-.17.19.19 0 00-.171 0l-.306.098a3.367 3.367 0 01-3.087-.512l-.036-.081a3.383 3.383 0 01-1.34-2.7v-.278a.333.333 0 00-.09-.234.28.28 0 00-.19-.072H7.797a.279.279 0 00-.261.297v.225A3.508 3.508 0 016.168 5.1l-.117.09a3.52 3.52 0 01-3.267.53.198.198 0 00-.126 0 .252.252 0 00-.108.135l-.746 2.348a.324.324 0 00.198.405l.162.045z'
                      )
                      path(
                        d='M9.002 12.144a3.15 3.15 0 01-3.089-3.763 3.148 3.148 0 015.708-1.135 3.148 3.148 0 01-2.619 4.898zm0-4.498a1.35 1.35 0 100 2.7 1.35 1.35 0 000-2.7z'
                      )

                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isSensorParamMissing'
                    ) {{ $t("objects_tabs.sensors.error_param12") }}

                .flex.flex-col.relative
                  span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.option2") }}
                  .flex.mt-3.items-center.justify-between

                    el-select.w-56(
                      v-model='newSensor.param2.param_key',
                      clearable,
                      filterable,
                      :class='{ "input-error": isSensorParamMissing }'
                    )
                      el-option(
                        v-for='item in terminalParams',
                        :key='item.value',
                        :label='item.label',
                        :value='item.value'
                      )
                    svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
                      width='18',
                      height='18',
                      fill='none',
                      xmlns='http://www.w3.org/2000/svg',
                      @click='$emit("param2", JSON.parse(JSON.stringify(newSensor.param2)))'
                    )
                      path(
                        d='M5.952 17.991a2.025 2.025 0 01-1.215-.413l-1.863-1.386a2.132 2.132 0 01-.44-2.896 1.853 1.853 0 00.206-1.673l-.054-.144A1.646 1.646 0 001.58 10.38h-.144A2.106 2.106 0 01.103 7.736L.84 5.397A1.96 1.96 0 012.593 3.92c.26-.018.521.016.767.101a1.737 1.737 0 001.602-.26l.117-.09a1.745 1.745 0 00.657-1.36v-.215A2.087 2.087 0 017.796 0h2.295a2.035 2.035 0 011.44.603c.396.403.617.946.612 1.511v.252a1.583 1.583 0 00.621 1.286l.1.072a1.566 1.566 0 001.43.234l.306-.099a2.035 2.035 0 012.592 1.359l.71 2.266a2.122 2.122 0 01-1.313 2.636l-.18.063a1.701 1.701 0 00-1.107 1.152 1.796 1.796 0 00.225 1.484l.234.342a2.14 2.14 0 01-.45 2.905l-1.809 1.395a2.018 2.018 0 01-2.898-.477l-.107-.153a1.575 1.575 0 00-1.35-.702 1.62 1.62 0 00-1.287.693l-.207.297a2.024 2.024 0 01-1.35.872 1.79 1.79 0 01-.351 0zM2.164 8.654a3.447 3.447 0 012.141 2.249v.108a3.597 3.597 0 01-.414 3.256.342.342 0 000 .459l1.935 1.466a.225.225 0 00.333-.063l.207-.297a3.393 3.393 0 015.58 0l.107.162a.27.27 0 00.162.108.223.223 0 00.171-.045l1.854-1.403a.324.324 0 00.063-.44l-.234-.343a3.597 3.597 0 01-.477-3.076 3.525 3.525 0 012.24-2.348l.18-.063a.306.306 0 00.172-.396l-.702-2.24a.315.315 0 00-.18-.17.19.19 0 00-.171 0l-.306.098a3.367 3.367 0 01-3.087-.512l-.036-.081a3.383 3.383 0 01-1.34-2.7v-.278a.333.333 0 00-.09-.234.28.28 0 00-.19-.072H7.797a.279.279 0 00-.261.297v.225A3.508 3.508 0 016.168 5.1l-.117.09a3.52 3.52 0 01-3.267.53.198.198 0 00-.126 0 .252.252 0 00-.108.135l-.746 2.348a.324.324 0 00.198.405l.162.045z'
                      )
                      path(
                        d='M9.002 12.144a3.15 3.15 0 01-3.089-3.763 3.148 3.148 0 015.708-1.135 3.148 3.148 0 01-2.619 4.898zm0-4.498a1.35 1.35 0 100 2.7 1.35 1.35 0 000-2.7z'
                      )

                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isSensorParamMissing'
                    ) {{ $t("objects_tabs.sensors.error_param12") }}

                .flex.flex-col.relative
                  span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.formula") }}
                  el-select.mt-3.w-56(
                    v-model='newSensor.formula',
                    clearable,
                    :class='{ "input-error": isSensorFormulaMissing }'
                  )
                    el-option(
                      v-for='item in formula',
                      :key='item.key',
                      :label='item.value',
                      :value='item.key'
                    )

                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isSensorFormulaMissing'
                    ) {{ $t("objects_tabs.sensors.error_formula") }}

            transition(name='el-zoom-in-top')
              .flex.flex-col.relative(v-if='isExpression')
                .flex.w-full.items-center
                  span.mt-3.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2 Выражение
                  el-tooltip(:open-delay="200" effect="dark" placement="top" :offset="0" :visible-arrow="true" popper-class='zino')
                    ul(slot='content')
                      li В Выражении можно объединять через арифметические операции:
                      br
                      li обращение к значениям любого параметра из сырых данных - #angle
                      br
                      li обращение к предыдущему значению параметра из сырых данных - #angle[-1]
                      br
                      li обращение к первому параметру(после линейнего преобразование) текущего датчика - $param1
                      br
                      li обращение к предыдущему значению первого параметра(после линейнего преобразование) текущего датчика - $param1[-1]
                      br
                      li обращение ко второму параметру(после линейнего преобразование) текущего датчика - $param2
                      br
                      li обращение к предыдущему значению второго параметра(после линейнего преобразование) текущего датчика - $param2[-1]
                      br
                      li константы - 1000 (константа 1000)
                      br
                      li обращение к значению других датчиков ${Название датчика}
                      br
                      li арифметические операции - + * / ^(степень)
                      br
                      li скобки - ( или )
                      br
                      li Побитовый контроль параметра - ":", например param101:3 - третий бить параметра param101
                      br

                    span.mb-1(class='skif-info-icon')
                      infoIcon

                .flex.my-3.items-center.justify-between
                  el-input(type='textarea' v-model='newSensor.expression')
            .flex.w-full.justify-between
              skif-checkbox.mt-3(
                :label='$t("objects_tabs.sensors.hide_in_reports")',
                v-model='newSensor.hide_in_reports'
              )
            .flex.w-full.justify-between
              skif-checkbox.mt-3(
                :label='$t("objects_tabs.sensors.hide_in_monitoring")',
                v-model='newSensor.hide_in_monitoring'
              )
            .flex.justify-end(class='w-full')
              skif-button(width='28', @click='createOrUpdateSensor') {{ $t("btn.save") }}


      tbody(v-if='!isNewSensor')
        tr.cursor-pointer.hover_bg-hoverrow(
          v-for='sensor in filterDataSensors',
          :key='sensor.id',
          @click='editSensor(sensor, { isUpdate: true })'
        )
          td.truncate.pr-2.text-darkblue
            el-tooltip(
              :open-delay='700',
              effect='dark',
              :content='sensor.name',
              placement='bottom',
              :offset='0',
              :visible-arrow='false'
            )
              span.font-bold.leading-10.text-sm {{ sensor.name }}
          td.truncate.pr-2.text-darkblue
            span.font-bold.leading-10.text-sm {{ sensor.type.value }}
          td.truncate.pr-2.text-darkblue
            span.font-bold.leading-10.text-sm {{ sensor.param1.param_key }}
          td.flex.items-center.justify-center
            svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
              width='12',
              height='16',
              fill='none',
              xmlns='http://www.w3.org/2000/svg',
              @click='(event) => { event.stopPropagation(); deleteSensor(sensor.id); }'
            )
              path(
                fill-rule='evenodd',
                clip-rule='evenodd',
                d='M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z'
              )

  Service-tab(
    v-if='activeTab === "ТО"',
    :unit='unit',
    :pickerOptions='pickerOptions',
    :dateFormat='dateFormat'
  )
  Shifts-tab(
    v-if='activeTab === "Смены"',
    :pickerOptions='pickerOptions',
    :dateTimeFormat='dateTimeFormat',
    :unit='unit',
    :role='role',
    :isCreateOrUpdateDriver='isCreateOrUpdateDriver',
    :isCreateOrUpdateDriverGroup='isCreateOrUpdateDriverGroup',
    @isnew-driver='handleNewDriver',
    @isnew-driver-group='handleNewDriverGroup'
    @is-edit-driver="isEditDriver = true"
  )
  Trailers-tab(
    v-if='activeTab === "Прицепы"',
    :pickerOptions='pickerOptions',
    :dateTimeFormat='dateTimeFormat',
    :unit='unit',
    :role='role',
    :multipleSelection='multipleSelection',
    @multiplselection='handlMultipleSelectionTrailer'
  )
  Events-tab(
    v-if='activeTab === "События"',
    :dateFormat='dateFormat',
    :eventsDateRange='eventsDateRange',
    :role='role',
    :unit='unit',
    @handleeventsdate='handleEventsDate',
    @create-event='createEvent',
    @filter-date='handlFilterEventDate',
    @changecheckedevent='changeCheckedEvent',
    @edit-event='handleEvent',
    @checkallevents='checkAllEvents'
  )
</template>

<script>
import {
  unittrailers,
  unitdriver,
  service,
  extra,
  sensors,
  events
} from './mixins'
import { mapState, mapGetters } from 'vuex'
import defaultValueDate from '@/utils/helpers.js'
import moment from 'moment'

export default {
  mixins: [unittrailers, unitdriver, service, extra, sensors, events],
  components: {
    event: () => import('./unitParams/event.vue'),
    'Svg-editunitTrash': () => import('./unitsSvg/Svg-EditunitTrash.vue'),
    MainTab: () => import('./subComponents/MainTab.vue'),
    AdditionalTab: () => import('./subComponents/AdditionalTab.vue'),
    SensorsTab: () => import('./subComponents/SensorsTab.vue'),
    ServiceTab: () => import('./subComponents/ServiceTab.vue'),
    ShiftsTab: () => import('./subComponents/ShiftsTab.vue'),
    TrailersTab: () => import('./subComponents/TrailersTab.vue'),
    EventsTab: () => import('./subComponents/EventsTab.vue'),
    infoIcon: () => import('@/assets/svg-icons/info.svg')
  },
  async created() {
    // console.log('get ports 22')
    const [from, to] = defaultValueDate()
    this.from = from
    this.to = to
    // this.getUnitTrailers()
    this.getUnitDrivers()
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
  },
  props: {
    title: Number,
    unit: Object,
    admin: Boolean
  },
  data() {
    return {
      currentSensor: null,
      isCreateOrUpdateDriver: false,
      isCreateOrUpdateDriverGroup: false,
      isExpression: false,
      isNewDriverGroup: false,
      isNewDriver: false,
      isEditDriver: false,
      unitImeis: null,
      eventlength: 2,
      pickerOptions: {
        firstDayOfWeek: 1
      },

      activeTab: 'Основные',
      tabs: [
        'Основные',
        'Дополнительные',
        'Датчики',
        'ТО',
        'Смены',
        'Прицепы',
        'События'
      ],
      from: '',
      to: '',
      filterStringExtra: '',
      filterStringSensors: '',
      filterStringEvents: '',
      //Validation
      isNameMissing: false,
      isUnitTypeMissing: false,
      isTerminalTypeMissing: false,
      isImeiMissing: false,
      isPhonenumberMissing: false,
      isIconMissing: false,
      // date time format
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      multipleSelection: [],
      isRegexValid: true,
      eventsDateRange: [
        moment().subtract(1, 'days').format('yyyy-MM-DD') + ' 00:00:00',
        moment(new Date()).format('yyyy-MM-DD') + ' 23:59:00'
      ]
    }
  },
  watch: {
    'unit.name': function (val) {
      this.isNameMissing = val === ''
    },
    'unit.type.key': function (val) {
      this.isUnitTypeMissing = val === ''
      console.log('current name is Missing!')
    },
    'unit.terminal_type.key': function (val) {
      this.isTerminalTypeMissing = val === ''
    },
    'unit.imei': function (val) {
      this.isImeiMissing = val === ''
    },
    // 'unit.phonenumber': function (val) {
    //   this.isPhonenumberMissing = val === ''
    // },
    // 'unit.icon.key': function (val) {
    //   this.isIconMissing = val === ''
    // },
    'newSensor.formula': function (val) {
      if (val === 'EXPR') {
        this.isExpression = true
      } else {
        this.isExpression = false
      }
    },
    newSensor: {
      handler(val) {
        this.currentSensor = val
      },
      deep: true
    },

    activeTab(newTab) {
      // reset tab triggers if tab was changed
      if (newTab !== 'Смены') {
        this.isNewDriver = false
        this.isNewDriverGroup = false
        this.isCreateOrUpdateDriver = false
        this.isEditDriver = false
      }
    }
  },
  methods: {
    handleNewDriver() {
      this.isNewDriver = true
      this.isNewDriverGroup = false
    },
    handleNewDriverGroup() {
      this.isNewDriver = false
      this.isNewDriverGroup = true
    },
    searching(val) {
      this.filterString = val
    },
    showHistoryChanges(unit) {
      this.$emit('show-history', { objects: 'units', objectId: unit })
    },
    handlparam1(param) {
      // console.log('par', param)
      this.$emit('param1', param)
    },
    handlparam2(param) {
      // console.log('par', param)
      this.$emit('param2', param)
    },
    handleEvent(event) {
      this.editEvent(event)
    },
    handlFilterEventDate(range) {
      this.eventsDateRange = range
    },
    createOrUpdateDriver() {
      this.isCreateOrUpdateDriver = !this.isCreateOrUpdateDriver
      this.isEditDriver = false
    },
    createOrUpdateDriverGroup() {
      // console.log('malaga')
      this.isCreateOrUpdateDriverGroup = !this.isCreateOrUpdateDriverGroup
    },

    editEvent(event) {
      console.log('edit', event)
      let unitId = this.unit.id
      this.$emit('update-unit-events', {
        unitId: unitId,
        fromModule: 'units',
        isEdit: true,
        event: event
      })
    },
    handlMultipleSelectionTrailer(val) {
      this.multipleSelection = { name: val.work_type }
    },

    handleEventsDate() {
      // console.log('disop')
      let formData = {
        from: 0,
        count: 100,
        sortDesc: false,
        conditions: [
          {
            field: 'unit.id',
            value: this.unit.id
          },
          { field: 'date_from', value: this.eventsDateRange[0] },
          { field: 'date_to', value: this.eventsDateRange[1] }
        ]
      }
      this.$store.dispatch('events/GET_LIST', formData)
      this.isAllCheckedEvent = false
      this.envetsSelected = []
    },
    createEvent() {
      let unitId = this.unit.id
      // console.log(
      //   'continue creation of new event ( open form , close redakt object) ',
      //   unitId
      // )
      this.$emit('update-unit-events', {
        unitId: unitId,
        fromModule: 'units'
      })
    },

    handlePattern(element) {
      let currentField = this.customFields.filter((el) => {
        return el.key === element.key
      })
      let currentPattern = new RegExp(currentField[0].pattern)
      this.isRegexValid = currentPattern.test(element.value)
      console.log(this.isRegexValid)
      element.isRegex = this.isRegexValid
    },

    valid() {
      let error = true
      if (this.unit.name === '') {
        this.isNameMissing = !this.unit.name
        error = false
      }
      if (this.unit.type.key === '') {
        this.isUnitTypeMissing = !this.unit.type.key
        error = false
      }
      if (this.unit.terminal_type.key === '') {
        this.isTerminalTypeMissing = !this.unit.terminal_type.key
        error = false
      }
      if (this.unit.imei === '') {
        this.isImeiMissing = !this.unit.imei
        error = false
      }
      // if (this.unit.phonenumber === '') {
      //   this.isPhonenumberMissing = !this.unit.phonenumber
      //   error = false
      // }
      // if (this.unit.icon.key === '') {
      //   this.isIconMissing = !this.unit.icon.key
      //   error = false
      // }

      return error
    },
    createOrUpdate() {
      let isValid = true
      console.log('saved')
      this.filterDataExtra.forEach((extra) => {
        if (extra.isRegex === false) {
          isValid = false
        }
      })
      if (this.valid() && isValid === true) {
        // eslint-disable-next-line vue/no-mutating-props
        this.unit.services = this.unit.services.map((service) => {
          if (typeof service.id === 'number') {
            delete service.id
          }
          return service
        })
        // eslint-disable-next-line vue/no-mutating-props
        this.unit.sensors = this.unit.sensors.map((sensor) => {
          if (typeof sensor.id === 'number') {
            delete sensor.id
          }
          return sensor
        })
        // console.log(this.unit, 'unit')
        this.title === 1
          ? this.$store.dispatch('units/CREATE_UNIT', this.unit).then((_) => {
              this.$emit('close')
            })
          : this.$store.dispatch('units/UPDATE_UNIT', this.unit).then((_) => {
              this.activeTab === 'Основные'
                ? this.$emit('close')
                : (this.activeTab = 'Основные')
            })
      }
    }
  },
  computed: {
    ...mapGetters({
      terminals: 'terminal/terminals',
      role: 'login/role',
      ports: 'ports/ports'
    }),
    ...mapState('properties', { customFields: (state) => state.customFields }),
    // ...mapState('units', { ports: state => state.ports }),
    ...mapState('login', {
      me: (state) => state.me
    }),

    unitModules() {
      return this.title === 1 ? this.tabs.slice(0, -3) : this.tabs
    },
    filterString: {
      get() {
        return this.activeTab === 'Дополнительные'
          ? this.filterStringExtra
          : this.activeTab === 'Датчики'
          ? this.filterStringSensors
          : this.activeTab === 'ТО'
          ? this.filterStringService
          : this.activeTab === 'Смены'
          ? this.filterStringDrivers
          : this.activeTab === 'События'
          ? this.filterStringEvents
          : this.filterStringTrailers
      },
      set(value) {
        if (this.activeTab === 'Дополнительные') {
          this.filterStringExtra = value
        } else if (this.activeTab === 'Датчики') {
          this.filterStringSensors = value
        } else if (this.activeTab === 'ТО') {
          this.filterStringService = value
        } else if (this.activeTab === 'Смены') {
          this.filterStringDrivers = value
        } else if (this.activeTab === 'События') {
          this.filterStringEvents = value
          // console.log('value v12', value)
        } else {
          this.filterStringTrailers = value
        }
      }
    },
    ...mapState('dictionary', {
      terminalTypes: (state) => state.terminal_type,
      unitTypes: (state) => state.unit_type,
      params: (state) => state.params
    })
  }
}
</script>
<style>
.multiSelectUnits .multiselect {
  width: 280px !important;
}

.hover_color-dark:hover {
  color: #39547a;
}

.zino {
  background: red;
  width: 460px;
  margin-left: 10px;
}
</style>

export default {
  data() {
    return {
      filterStringService: '',
      isNewService: false,
      programaticServiceReset: false,
      newService: {},
      isServiceNameMissing: false,
      isServiceDescriptionMissing: false,
      isServiceLastDaysMissing: false,
      isServiceAnyCheckbox: false,
      isServiceIntervalKmMissing: false,
      isServiceLastKmMissing: false,
      isServiceIntervalDaysMissing: false,
      isServiceIntervalMotohoursMissing: false,
      isServiceLastMotohoursMissing: false
    }
  },
  watch: {
    'newService.name': function(val) {
      this.isServiceNameMissing = !this.programaticServiceReset && val === ''
    },
    'newService.description': function(val) {
      this.isServiceDescriptionMissing =
        !this.programaticServiceReset && val === ''
    },
    'newService.lastDays': function(val) {
      this.isServiceLastDaysMissing =
        !this.programaticServiceReset && val === ''
    },
    'newService.isActiveKm': function(val) {
      this.isServiceAnyCheckbox =
        !this.programaticServiceReset &&
        !(
          val ||
          this.newService.isActiveDays ||
          this.newService.isActiveMotohours
        )
      this.isServiceIntervalKmMissing =
        !this.programaticServiceReset &&
        val &&
        this.newService.intervalKm === ''
      this.isServiceLastKmMissing =
        !this.programaticServiceReset && val && this.newService.lastKm === ''
    },
    'newService.isActiveDays': function(val) {
      this.isServiceAnyCheckbox =
        !this.programaticServiceReset &&
        !(
          val ||
          this.newService.isActiveKm ||
          this.newService.isActiveMotohours
        )
      this.isServiceIntervalDaysMissing =
        !this.programaticServiceReset &&
        val &&
        this.newService.intervalDays === ''
    },
    'newService.isActiveMotohours': function(val) {
      this.isServiceAnyCheckbox =
        !this.programaticServiceReset &&
        !(val || this.newService.isActiveKm || this.newService.isActiveDays)
      this.isServiceIntervalMotohoursMissing =
        !this.programaticServiceReset &&
        val &&
        this.newService.intervalMotohours === ''
      this.isServiceLastMotohoursMissing =
        !this.programaticServiceReset &&
        val &&
        this.newService.lastMotohours === ''
    },
    'newService.intervalDays': function(val) {
      this.isServiceIntervalDaysMissing =
        !this.programaticServiceReset &&
        this.newService.isActiveDays &&
        val === ''
    },
    'newService.intervalKm': function(val) {
      this.isServiceIntervalKmMissing =
        !this.programaticServiceReset &&
        this.newService.isActiveKm &&
        val === ''
    },
    'newService.lastKm': function(val) {
      this.isServiceLastKmMissing =
        !this.programaticServiceReset &&
        this.newService.isActiveKm &&
        val === ''
    },
    'newService.intervalMotohours': function(val) {
      this.isServiceIntervalMotohoursMissing =
        !this.programaticServiceReset &&
        this.newService.isActiveMotohours &&
        val === ''
    },
    'newService.lastMotohours': function(val) {
      this.isServiceLastMotohoursMissing =
        !this.programaticServiceReset &&
        this.newService.isActiveMotohours &&
        val === ''
    }
  },
  methods: {
    createService() {
      this.isNewService = !this.isNewService
      this.programaticServiceReset = true
      this.newService = {
        name: '',
        description: '',
        isActiveKm: false,
        intervalKm: '',
        lastKm: '',
        isActiveDays: false,
        intervalDays: '',
        lastDays: '',
        isActiveMotohours: false,
        intervalMotohours: '',
        lastMotohours: ''
      }
      setTimeout(() => (this.programaticServiceReset = false), 100)
    },
    validService() {
      let errors = 0
      if (this.newService.name === '') {
        this.isServiceNameMissing = !this.newService.name
        errors = 1
      }
      if (this.newService.description === '') {
        this.isServiceDescriptionMissing = !this.newService.description
        errors = 1
      }
      if (this.newService.lastDays === '') {
        this.isServiceLastDaysMissing = !this.newService.lastDays
        errors = 1
      }
      if (
        !(
          this.newService.isActiveKm ||
          this.newService.isActiveDays ||
          this.newService.isActiveMotohours
        )
      ) {
        this.isServiceAnyCheckbox = !(
          this.newService.isActiveKm || this.newService.isActiveDays
        )
        errors = 1
      }
      if (this.newService.isActiveDays && this.newService.intervalDays === '') {
        this.isServiceIntervalDaysMissing =
          this.newService.isActiveDays && this.newService.intervalDays === ''
        errors = 1
      }
      if (
        this.newService.isActiveKm &&
        this.newService.intervalKm === '' &&
        this.newService.lastKm === ''
      ) {
        this.isServiceIntervalKmMissing =
          this.newService.isActiveKm && this.newService.intervalKm === ''
        this.isServiceLastKmMissing =
          this.newService.isActiveKm && this.newService.lastKm === ''
        errors = 1
      }
      if (
        this.newService.isActiveMotohours &&
        this.newService.intervalMotohours === '' &&
        this.newService.lastMotohours === ''
      ) {
        this.isServiceIntervalMotohoursMissing =
          this.newService.isActiveMotohours &&
          this.newService.intervalMotohours === ''
        this.isServiceLastMotohoursMissing =
          this.newService.isActiveMotohours &&
          this.newService.lastMotohours === ''
        errors = 1
      }
      return errors
    },
    editService(service) {
      this.programaticServiceReset = true
      this.newService = JSON.parse(JSON.stringify(service))
      setTimeout(() => (this.programaticServiceReset = false), 100)
      this.isNewService = true
    },
    createOrUpdateService() {
      if (!this.validService()) {
        if (this.newService.id) {
          const serviceIndex = this.unit.services.findIndex(
            service => service.id === this.newService.id
          )
          this.unit.services = [
            ...this.unit.services.slice(0, serviceIndex),
            JSON.parse(JSON.stringify(this.newService)),
            ...this.unit.services.slice(serviceIndex + 1)
          ]
        } else {
          this.unit.services.push(
            JSON.parse(JSON.stringify({ ...this.newService, id: Date.now() }))
          )
        }
        this.isNewService = false
      }
    },
    deleteService(id) {
      const serviceIndex = this.unit.services.findIndex(
        service => service.id === id
      )
      this.unit.services.splice(serviceIndex, 1)
    }
  },
  computed: {
    filterDataServices() {
      return this.unit.services.filter(
        service =>
          JSON.stringify(service)
            .toLowerCase()
            .indexOf(this.filterStringService.toLowerCase()) !== -1
      )
    }
  }
}
